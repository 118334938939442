import { createReducer } from "redux-act";
import * as action from "../actions/languageAction";

const initialState = {
  language: "sk",
};

const setInitialState = () => {
  let localStorageLanguage = localStorage.getItem("language");

  if (localStorageLanguage === "sk" || localStorageLanguage === "en")
    initialState.language = localStorageLanguage;

  return initialState;
};

const languageReducer = createReducer(
  {
    [action.changeLanguage]: (state, payload) => {
      return {
        ...state,
        language: payload,
      };
    },
  },
  setInitialState()
);

export default languageReducer;
