import Keycloak from "keycloak-js";

import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Axios from "axios";

import Routers from "./components/Routers";

import global from "./translations/global.json";
import adminGlobal from "./translations/admin/global.json";
import Loading from "./components/admin/Loading";
import { getLocaleByShortcut } from "./utils/antdLocale.jsx";
import { ConfigProvider } from "antd";

const LayoutAdmin = React.lazy(() => import("./components/admin/LayoutAdmin"));
const Themes = {
  light: `${process.env.PUBLIC_URL}/css/antd.css`,
  dark: `${process.env.PUBLIC_URL}/css/antd.dark.css`,
};

function App(props) {
  const language = useSelector((state) => state.language.language);
  const theme = useSelector((state) => state.userSettings.theme);
  const [keycloak, setKeycloak] = useState(null);
  const [keycloakReady, setKeycloakReady] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const combinedTranslation = {
    en: {
      ...global["en"],
      ...adminGlobal["en"],
    },
    sk: {
      ...global["sk"],
      ...adminGlobal["sk"],
    },
  };

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));

    if (keycloak == null) {
      Axios.get(`api/keycloak/config`).then((response) => {
        setKeycloak(new Keycloak({ ...response.data }));
      });
    }
  }, []);

  if (keycloak == null) return null;

  const onTokenExpired = () => {
    keycloak.updateToken(300);
  };

  return (
    <ReactKeycloakProvider
      initOptions={{
        onLoad: "login-required",
      }}
      authClient={keycloak}
      onEvent={(event) => {
        if (event === "onReady") setKeycloakReady(true);
        if (event === "onTokenExpired") onTokenExpired();
      }}
      onTokens={(event) => {
        localStorage.setItem("token", event.token);
      }}
    >
      <IntlProvider
        key={language}
        locale={language}
        messages={combinedTranslation[language]}
      >
        <ThemeSwitcherProvider themeMap={Themes} defaultTheme={theme}>
          <Suspense fallback={<Loading />}>
            <ConfigProvider locale={getLocaleByShortcut(language)}>
              <LayoutAdmin>
                <Suspense fallback={<Loading />}>
                  {keycloakReady ? <Routers /> : <Loading />}
                </Suspense>
              </LayoutAdmin>
            </ConfigProvider>
          </Suspense>
        </ThemeSwitcherProvider>
      </IntlProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
